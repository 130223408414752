<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Inventário de instrumentos"
      :route="route"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      :fields="fields"
      :unidades="unidades"
      tipoRelatorio="relatorioItens"
      :fieldsFiltered="false"
    />
  </div>
</template>

<script>

import GenericReport from '@/components/Table/GenericReport';
import unidadeServices from '../../../services/unidade';

export default {
  name: 'relatorioItens',
  components: {
    GenericReport,
  },
  data() {
    return {
      route: 'report/itens',
      unidades: [],
      topFilters: {
        id_unidade: {
          type: 'select',
          firstOption: {
            value: null,
            label: 'Unidade',
          },
          options: [
            { value: -1, text: 'Rede' },
          ],
        },
        id_setor: {
          type: 'select',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Setor',
          },
        },
      },
      sideFilters: {
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
        fabricante: {
          type: 'input',
          label: 'Fabricante',
        },
      },
      fields: [
        {
          label: 'Descrição',
          key: 'descricao',
        },
        {
          label: 'Quantidade de peças do item',
          key: 'quantidade_de_peças_do_item',
        },
        {
          label: 'Id fabricante',
          key: 'id_fabricante',
        },
        {
          label: 'Fabricante',
          key: 'fabricante',
        },
        {
          label: 'Quantidade do inventário',
          key: 'quantidade_do_inventário',
        },
      ],
    };
  },
  async created() {
    this.unidades = await unidadeServices.getUnidades();
    this.topFilters.id_unidade.options = this.topFilters.id_unidade.options.concat(this.unidades.map((unid) => ({
      value: unid.id_unidade,
      text: unid.nome,
    })));
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
